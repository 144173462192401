import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import ReactGA from "react-ga";

var firebaseConfig = {
  apiKey: "AIzaSyCz2utopSbFsK-n92rXs3NHHcim0ve3CfM",
  authDomain: "fxgetactive-web.firebaseapp.com",
  databaseURL: "https://fxgetactive-web.firebaseio.com",
  projectId: "fxgetactive-web",
  storageBucket: "fxgetactive-web.appspot.com",
  messagingSenderId: "8616801338",
  appId: "1:8616801338:web:5ccecca0024d851989f103",
  measurementId: "G-P2LQLJ8R3C",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  ReactGA.event({
    category: "Button",
    action: "Google Signin pressed",
  });
  auth.signInWithPopup(provider);
};

export const createUserWithEmailAndPassword = (
  email,
  password,
  displayName
) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((result) => {
      result.user
        .updateProfile({ displayName: displayName })
        .then(console.log("name updated"));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const verifyAccount = (callback) => {
  auth.currentUser
    .sendEmailVerification()
    .then(function () {
      console.log("Verification email : Sent");
    })
    .catch(function (error) {
      callback(error.code);
      console.log("Verification email  : ", error.code);
    });
};

export const resetPassword = (email, callback) => {
  auth
    .sendPasswordResetEmail(email)
    .then(function () {
      callback("Sent");
      console.log("Reset password email : Sent");
    })
    .catch(function (error) {
      callback(error.code);
      console.log("Reset password email : ", error.code);
    });
};

export const signInWithEmailAndPassword = (email, password) => {
  auth.signInWithEmailAndPassword(email, password);
};
