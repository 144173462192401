import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { Link } from "react-router-dom";
import { auth } from "firebase";
import { UserContext } from "../../providers/UserProvider";

import PopupComponent from "../popup/popup.component.jsx";
import SignIn from "../signin/signin.component.jsx";

import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";

import ReactGA from "react-ga";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 overflow-hidden`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;

const Heading = tw(SectionHeading)``;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Card = tw.div`h-full flex! flex-col sm:border max-w-sm  relative focus:outline-none min-w-full`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-80 h-72 sm:h-80 bg-cover bg-center rounded`,
]);

// const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-2xl relative focus:outline-none`;
// const CardImage = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`w-full h-72 sm:h-80 bg-cover bg-center rounded sm:rounded-tl-2xl sm:sm:rounded-tr-2xl`
// ]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none  py-3 sm:py-6`;
//const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-bl-2xl sm:rounded-br-2xl py-3 sm:py-6`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-white outline-none shadow-lg `}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

//let cardClickedBeforeLogin = false;
let clickedCard = "";

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [cardClickedBeforeLogin, setcardClickedBeforeLogin] = useState(null);
  const { user } = useContext(UserContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // imageSrc: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w001.png",
      title: "saladslicer",
      id: "w001",
      description: "Movement",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w002.png",
      title: "ninjarun",
      id: "w002",
      description: "Squats + Jump",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w005.png",
      title: "getfitwithgranny",
      id: "w005",
      description: "Workout",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w003.png",
      title: "ropedin",
      id: "w003",
      description: "Squats",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w004.png",
      title: "kingofthetree",
      id: "w004",
      description: "Jump",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w008.png",
      title: "dancewithgranny",
      id: "w008",
      description: "Dance Workout",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w009.png",
      title: "Asana",
      id: "w009",
      description: "Yoga",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w007.png",
      title: "granny",
      id: "w007",
      description: "I-Workout",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w010.png",
      title: "galacton",
      id: "w010",
      description: "Movement + Squats",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    },
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w013.png",
      title: "goldenbubble",
      id: "w013",
      description: "Movement",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    }
    ,
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/AerialRush.png",
      title: "aerialrush",
      id: "w011",
      description: "Movement + Squats",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    }
    ,
    {
      imageSrc:
        "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/workouts/w015.png",
      title: "Bus & Subway",
      id: "w015",
      description: "Movement + Squats + Jump",
      locationText: "",
      pricingText: "",
      rating: "4.8",
    }
  ];

  if (user && cardClickedBeforeLogin) {
  }

  return (
    // (user && cardClickedBeforeLogin) ?
    //   <Redirect to={{ pathname: '/workouts/' + clickedCard, state: { workoutID: clickedCard } }} />
    //   :
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>
            {
              <>
                Popular <HighlightedText>Workouts</HighlightedText>
              </>
            }
          </Heading>
          <Controls>
            <PrevButton
              onClick={(event) => {
                sliderRef.slickPrev();
                ReactGA.event({
                  category: "Button",
                  action: "Previous Button pressed",
                });
              }}
            >
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton
              onClick={(event) => {
                sliderRef.slickNext();
                ReactGA.event({
                  category: "Button",
                  action: "Next Button pressed",
                });
              }}
            >
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) =>
            user ? (
              <Card
                key={index}
                onClick={() => {
                  ReactGA.event({
                    category: "Button",
                    action: "Button " + index + " pressed",
                  });
                }}
              >
                <Link
                  to={{
                    pathname: "/workouts/" + card.id,
                    workoutID: card.id,
                  }}
                >
                  <CardImage imageSrc={card.imageSrc} />
                  <PrimaryButton>{card.description}</PrimaryButton>
                </Link>
              </Card>
            ) : (
              <Card
                key={index}
                onClick={() => {
                  ReactGA.event({
                    category: "Button",
                    action: "Button " + index + " pressed but not logged in",
                  });

                  console.log("Clicked");
                  window.setSigninPopupComponent(<SignIn />);
                  window.signInToggle();
                  setcardClickedBeforeLogin(true);
                  clickedCard = card.id;
                }}
              >
                <CardImage imageSrc={card.imageSrc} />
                <PrimaryButton>{card.description}</PrimaryButton>
              </Card>
            )
          )}
        </CardSlider>
      </Content>
    </Container>
  );
};
