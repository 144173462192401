import React, { useState, useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../header/header.component";
import SignUp from "../signup/signup.component";
import SignIn from "../signin/signin.component";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import DesignIllustration from "../../images/design-illustration.svg";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/fx/hero/hero-lb.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/fx/hero/hero-rb.svg";
import { Link } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";

import ReactGA from "react-ga";
import ReactPlayer from "react-player";

const Container = tw.div`relative`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto `;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-100 absolute left-0 bottom-0 h-auto w-auto transform -translate-x-2/3  z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-100 absolute w-auto h-auto right-0 bottom-0 transform translate-x-10 translate-y-10 z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-white outline-none shadow-lg `}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "Modern React Templates, Just For You",
  description = "Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://youtube.com/embed/ZaA9CjTtfcI",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useContext(UserContext);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <Container style={{ backgroundColor: "#f6f6f6" }}>
      <TwoColumn>
        <LeftColumn>
          <Heading>{heading}</Heading>
          <Paragraph>{description}</Paragraph>
          <Actions>
            {user ? (
              <Link
                to={{
                  pathname: "/workouts/w001",
                  gamename: "saladslicer",
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "Button",
                    action: "Workout Now pressed",
                  });
                }}
              >
                <PrimaryButton>{primaryButtonText}</PrimaryButton>
              </Link>
            ) : (
              <PrimaryButton
                onClick={() => {
                  ReactGA.event({
                    category: "Button",
                    action: "Workout Now pressed but Not Logged In",
                  });
                  console.log("Clicked");
                  window.setSigninPopupComponent(<SignIn />);
                  window.signInToggle();
                }}
              >
                {primaryButtonText}
              </PrimaryButton>
            )}
            {/* <WatchVideoButton onClick={toggleModal}>
              <span className="playIconContainer">
                <PlayIcon className="playIcon" />
              </span>
              <span className="playText">{watchVideoButtonText}</span>
            </WatchVideoButton> */}
          </Actions>
        </LeftColumn>
        <RightColumn>
          <IllustrationContainer>
            {/* <img
              css={imageCss}
              src={
                user
                  ? imageSrc
                  : "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/illustrations/front-illustration2.gif"
              }
              alt="Hero"
            /> */}
            <ReactPlayer
              url="https://vimeo.com/499063867"
              loop={true}
              muted={true}
              playing={true}
            />
          </IllustrationContainer>
        </RightColumn>
      </TwoColumn>
      <DecoratorBlob1 />
    </Container>
  );
};

{
  /* <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" /> */
}
