import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { isMobile } from "react-device-detect";
import * as serviceWorker from "./serviceWorker";

import Modal from "react-modal";
Modal.setAppElement("#root");

ReactDOM.render(
  <BrowserRouter>
    {isMobile ? (
      <div>
        {" "}
        <h2 style={{ color: "gray", align: "center" }}>
          This content is best experienced on your laptops / desktops.
        </h2>{" "}
        <h1> Currrently unavailable on mobile </h1>
      </div>
    ) : (
      <App />
    )}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
