import React, { useContext } from "react";
import tw from "twin.macro";



import AnimationRevealPage from "../helpers/AnimationRevealPage.js";

import MainFeature from "../components/features/feature.component.jsx";
import Hero from "../components/hero/hero.component.jsx";
import SliderCards from "../components/cards/slidercards.component.jsx";
import Loader from "../components/loader/loader.component.jsx";

//Providers
import { UserContext } from "../providers/UserProvider";

const Subheading = tw.span`tracking-wider text-sm font-medium`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const imageCss = tw`rounded-4xl`;

const Mainpage = () => {
    

    //  const user = useContext(UserContext);
    return (
        <div>
            <Hero
                heading={<>Snack sized workouts<HighlightedText>fun social interactive</HighlightedText></>}
                description="Are you someone who tried hitting the Gym couple of times ? Combat sedentary lifestyle, take first steps towards your fitness goal"
                imageSrc="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/illustrations/front-illustration.gif"
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Workout Now"
                watchVideoButtonText="Preview"
                primaryButtonUrl="#sliderarea"
            />
            {/* <AnimationRevealPage> */}
                {/* <p id = "sliderarea"> */}
                <SliderCards />
                {/* </p> */}
                <MainFeature
                    subheading={<Subheading>experience fitness</Subheading>}
                    heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
                    statistics={[
                        {
                            key: "Players",
                            value: "8220+",
                        },
                        {
                            key: "Workouts",
                            value: "12500+"
                        },
                        {
                            key: "Avg Time",
                            value: "120s"
                        }
                    ]}
                    primaryButtonText="Order Now"
                    description="We create an environment that enables employees to be physically active. The platform
                    offers snack-sized workouts consisting of Active Games, HIIT, Dance, and Cardio. We are
                    transforming fitness as an experience making it more fun, social, and interactive."
                    primaryButtonUrl="https://order.now.com"
                    imageInsideDiv={false}
                    imageSrc="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/misc/banner.png"
                    imageCss={Object.assign(tw`bg-cover`, imageCss)}
                    imageContainerCss={tw`md:w-1/2 h-auto`}
                    imageDecoratorBlob={true}
                    imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
                    textOnLeft={true}
                />
            {/* </AnimationRevealPage> */}

        </div>
    )
}

export default Mainpage
