import React, { useState, useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/front_illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";


import { UserContext } from "../../providers/UserProvider";


import { auth, signInWithGoogle, resetPassword } from "../../firebase";
import SignUp from "components/signup/signup.component.jsx";


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`bg-white text-gray-900 sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div` `; //lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-gray-100 shadow-lg
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none cursor-pointer focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
let Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-white outline-none shadow-lg `}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


const SignIn = ({

  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Sign In",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com"
    }
  ],
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "#",
}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => { setEmailSuccessText("Forgot Password ?"); setModalIsOpen(!modalIsOpen); }

  const { user } = useContext(UserContext);

  //sign-in
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);


  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    console.log("Email Validation : ", emailFormat.test(String(email).toLowerCase()));

    (emailFormat.test(String(email).toLowerCase())) ? setEmailBorderColor("#edf2f7") : setEmailBorderColor("red");
    (password) ? setPasswordBorderColor("#edf2f7") : setPasswordBorderColor("red");

    if (emailFormat.test(String(email).toLowerCase()) && password) {

      window.setLoading(true);
      auth.signInWithEmailAndPassword(email, password).then((result) => {
        window.setLoading(false);
        window.signInToggle();
      }).catch(error => {
        window.setLoading(false);
        setEmailBorderColor("red");
        setPasswordBorderColor("red");
        setError("Error signing in with password and email!");
        console.error("Error signing in with password and email", error);
      });

    }
    event.preventDefault();
  };

  const onChangeHandler = (event) => {
    console.log(event)
    const { name, value } = event.currentTarget;
    setEmailSuccessText("Forgot Password ?");
    setEmailBorderColor("#edf2f7");
    setPasswordBorderColor("#edf2f7")
    if (name === 'userEmail') {
      setEmail(value);
    }
    else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  const [emailBorderColor, setEmailBorderColor] = useState("");
  const [passwordBorderColor, setPasswordBorderColor] = useState("");

  const [emailSuccessText, setEmailSuccessText] = useState("Forgot Password ?");

  let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Content>
      <MainContainer>

        {/* <LogoLink href={logoLinkUrl}>
          <LogoImage src={"https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/logo/sitelogo.png"} />
        </LogoLink> */}
        <MainContent>

          <FormContainer>

            <SocialButtonsContainer>
              {socialButtons.map((socialButton, index) => (
                <SocialButton key={index} onClick={(event) => {
                  window.signInToggle(false);
                  signInWithGoogle();
                }}>
                  <span className="iconContainer">
                    <img src={socialButton.iconImageSrc} className="icon" alt="" />
                  </span>
                  <span className="text">{socialButton.text}</span>
                </SocialButton>
              ))}
            </SocialButtonsContainer>

            <DividerTextContainer>
              <DividerText>Or Sign in with your e-mail</DividerText>
            </DividerTextContainer>

            <Form>
              <Input style={{ borderColor: emailBorderColor }} name="userEmail" type="email" placeholder="Email" value={email} onChange={(event) => onChangeHandler(event)} required={true} />
              <Input style={{ borderColor: passwordBorderColor }} name="userPassword" type="password" placeholder="Password" value={password} onChange={(event) => onChangeHandler(event)} />
              <SubmitButton type="submit" onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password); }}
              >
                <SubmitButtonIcon className="icon" />
                <span className="text">{submitButtonText}</span>
              </SubmitButton>
            </Form>

            <p tw="mt-6 text-xs text-gray-600 text-center">
              <a onClick={() => {

                if (emailFormat.test(String(email).toLowerCase())) {
                  if (emailSuccessText == "Forgot Password ?") {
                    resetPassword(email, (res) => {
                      console.log(res);
                      if (res != "auth/user-not-found") {
                        setEmailSuccessText("Please check your email to rest password!");
                      } else {
                        if (emailSuccessText == "User account not found!") {
                          setEmailSuccessText("Forgot Password ?");
                        } else {
                          setEmailSuccessText("User account not found!");
                        }

                      }
                    });

                  } else {
                    if (emailSuccessText == "User account not found!") {
                      setEmailSuccessText("Forgot Password ?");
                    }
                  }
                } else {
                  if (emailSuccessText == "Forgot Password ?") {

                    setEmailBorderColor("red");
                    setPasswordBorderColor("#edf2f7")
                  }
                }

              }} tw="border-b border-gray-500 border-dotted cursor-pointer">
                {emailSuccessText}
              </a>
            </p>
            <p tw="mt-8 text-sm text-gray-600 text-center">
              Dont have an account?{" "}
              <a onClick={() => { window.signInToggle(); window.setSignupPopupComponent(<SignUp />); window.signUpToggle(); }} tw="border-b border-gray-500 border-dotted cursor-pointer">
                Sign Up
                </a>
            </p>

          </FormContainer>
        </MainContent>

      </MainContainer>

      <IllustrationContainer>
        <IllustrationImage imageSrc={illustrationImageSrc} />
      </IllustrationContainer>
    </Content>
  );
}
export default SignIn;
