import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-white outline-none shadow-lg `}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const PopupComponent = ({ callback }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => { setModalIsOpen(!modalIsOpen); }

  const [popupContent, setPopupContent] = useState(<></>);
  const setContent = (content) => {setPopupContent(content)}

  const init = () => {
    callback(toggleModal, setContent);
  }

  init();

  return (
    <StyledModal
      closeTimeoutMS={300}
      className="mainHeroModal"
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
    >
      <CloseModalButton onClick={toggleModal}>
        <CloseIcon tw="w-6 h-6" />
      </CloseModalButton>
      <div className="content">
        {popupContent}
      </div>
    </StyledModal>
  );
}

export default PopupComponent;

