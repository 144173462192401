import React, { useState, useEffect, useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`bg-white bg-opacity-75 flex flex-auto inset-0 fixed overflow-y-auto overflow-x-hidden z-50 `}
  }
  &.mainHeroModal__content {
    ${tw` absolute inset-0 flex justify-center items-center`}
  }
  .content {
    ${tw`flex justify-center`}
  }
  
`;

const LogoImg = tw.img`mx-4 w-12 cursor-pointer`

let loaderIterate = 0;

const Loader = ({ isLoadingProp }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => { setModalIsOpen(!modalIsOpen); }

  const init = () => {

   // console.log("Value : ", isLoadingProp);

    if (loaderIterate == 0) {
      setModalIsOpen(isLoadingProp);
      loaderIterate = 1;
    }

    window.setLoading = setModalIsOpen;
  }

  init();

  return (
    <StyledModal
      closeTimeoutMS={300}
      className="mainHeroModal"
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      shouldCloseOnOverlayClick={true}
    >
      <div className="content" >
        <LogoImg src="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/loader/loader.gif" />
      </div>
    </StyledModal>
  );

};

export default Loader;