import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import { CopyToClipboard } from 'react-copy-to-clipboard';



var LGColumn = tw.div`max-w-2xl my-3 px-3 shadow-xl  pt-4 pb-4 bg-gray-300 flex justify-center`;  // Link-generator column

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`bg-black bg-opacity-75 flex flex-auto inset-0 fixed overflow-y-auto overflow-x-hidden z-50`}
  }
  &.mainHeroModal__content {
    ${tw` absolute inset-0 flex justify-center items-center`}
  }
  .content {
    ${tw`flex justify-center`}
  }

 
`;



const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const LogoImg = tw.img`mx-4 w-12 cursor-pointer`

const HighlightedText = tw.span`bg-blue-600 text-gray-300 px-4 transform inline-block`;

const LinkGenerator = ({ props }) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => { setModalIsOpen(!modalIsOpen); }


    const ROOM_ID = props.match.params.roomID;

    const [joinLink, setJoinink] = useState('');

    useEffect(() => {

        if (ROOM_ID) {

            window.unityRoomID = ROOM_ID;

        } else {

            console.log("ROOM_ID : NOT-EXIST");
        }

        window.setRoomLinkURL = setRoomLinkURL;

    }, []);


    const setRoomLinkURL = (link) => {
        toggleModal();
        setJoinink(link);
    }

    return (
        <StyledModal
            closeTimeoutMS={300}
            className="mainHeroModal"
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            shouldCloseOnOverlayClick={true}
        >
            <CloseModalButton onClick={toggleModal}>
                <CloseIcon tw="w-6 h-6" />
            </CloseModalButton>
            <div className="content" >
                <LGColumn>
                    <div style={{ color: "black" }}>{<>Share this link to play with friends<HighlightedText>{joinLink}</HighlightedText></>}</div>
                    <CopyToClipboard text={joinLink}
                        onCopy={() => { console.log("URL Coppied : ", joinLink); toggleModal()}}>
                        <LogoImg src="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/icons/link.png" />
                    </CopyToClipboard>
                </LGColumn>
            </div>
        </StyledModal>
    );
}

export default LinkGenerator;