import React, { Component, createContext, useState, useEffect } from "react";
import { auth, verifyAccount } from "../firebase";

import { useHistory, useLocation } from "react-router-dom";

export const UserContext = createContext({ user: null });

window.Xauth = "";

window.previousPath = "";
let oneTimeLocationFetch = 0;

const UserProvider = (props) => {

  let history = useHistory();
  let location = useLocation();

  if (oneTimeLocationFetch == 0) {
    window.previousPath = location;
    oneTimeLocationFetch = 1;
  }

  const [user, setUser] = useState("");
  const [cacheURL, setCacheURL] = useState("");

  useEffect(() => {

    auth.onAuthStateChanged(userAuth => {

      if (userAuth !== null) {

        setUser({ user: userAuth });

        history.replace(window.previousPath.pathname);

        if (userAuth.emailVerified) {

          getUserAccountInfo(userAuth, (response) => {
            if (response.errorCode) {
              if (response.errorCode == 1001) {
                registerPlayFab(userAuth, (registerResponse) => {
                  window.Xauth = registerResponse.data.SessionTicket;
                  window.setLoading(false);
                  //console.log("Playfab Register Success!");
                });
              }
            } else {
              loginWithEmailAddress(userAuth, (loginResponse) => {
                window.Xauth = loginResponse.data.SessionTicket;
                window.setLoading(false);
                //console.log("Playfab Login Success!");
              });
            }
          });

        } else {
          //console.log("Email verification pending!");
          verifyAccount(() => { 
            //console.log("Verification sent!") 
          });
          window.setLoading(false);
        }

      } else {
        window.setLoading(false);
        setUser(null);
        //console.log("user");
      }
    });

  }, []);

  useEffect(() => {
    //console.log("Cache URL : " + cacheURL)

  }, [cacheURL])

  const getUserAccountInfo = (userAuth, callback) => {
    let headers = { 'Content-Type': 'application/json', 'X-SecretKey': 'UJ1HY3W6QPDA58EJRXDXQDSF5O648TDXKSJHTGYDXTUCPM9CF6' };
    let body = {
      "Email": userAuth.email,
      "TitleDisplayName": (!userAuth.displayName) ? "User" : userAuth.displayName.replace(" ", ""),
    };

    window.URLpost("https://ABF47.playfabapi.com/Admin/GetUserAccountInfo", headers, body, callback);
  }

  const getTittleData = (callback) => {
    let headers = { 'Content-Type': 'application/json', 'X-SecretKey': 'UJ1HY3W6QPDA58EJRXDXQDSF5O648TDXKSJHTGYDXTUCPM9CF6' };
    let body = {};
    window.URLpost("https://ABF47.playfabapi.com/Server/GetTitleData", headers, body, callback);
  }

  const registerPlayFab = (userAuth, callback) => {
    let headers = { 'Content-Type': 'application/json' };

    let body = {
      "DisplayName": userAuth.displayName.replace(" ", ""),
      "Email": userAuth.email,
      "Password": userAuth.uid,
      "TitleId": "ABF47",
      "RequireBothUsernameAndEmail": false
    };

    window.URLpost("https://ABF47.playfabapi.com/Client/RegisterPlayFabUser", headers, body, callback);
  }

  const loginWithEmailAddress = (userAuth, callback) => {
    //console.log(userAuth.uid);
    let headers = { 'Content-Type': 'application/json' };
    let body = {
      "Email": userAuth.email,
      "Password": userAuth.uid,
      "TitleId": "ABF47"
    };
    window.URLpost("https://ABF47.playfabapi.com/Client/LoginWithEmailAddress", headers, body, callback);
  }

  // const getPlayerStatistics = (callback) => {
  //   let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
  //   let body = {};
  //   window.URLpost("https://ABF47.playfabapi.com/Client/GetPlayerStatistics", headers, body, callback);
  // }

  const updatePlayerData = (callback) => {
    let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
    let body = {};
    window.URLpost("https://ABF47.playfabapi.com/Client/UpdateUserData", headers, body, callback);
  }

  const getPlayerData = (callback) => {
    let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
    let body = {};
    window.URLpost("https://ABF47.playfabapi.com/Client/GetUserData", headers, body, callback);
  }

  const setURL = (value) => {
    setCacheURL(value)
  }
  // const addFriend = (callback) => {
  //   let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
  //   let body = {};
  //   URLpost("https://ABF47.playfabapi.com/Client/AddFriend", headers, body, callback);
  // }

  // const getFriendsList = (callback) => {
  //   let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
  //   let body = {};
  //   URLpost("https://ABF47.playfabapi.com/Client/GetFriendsList", headers, body, callback);
  // }

  // const getFriendsLeaderboard = (callback) => {
  //   let headers = { 'Content-Type': 'application/json', 'X-authentication:': '' };
  //   let body = {
  //     "StatisticName": "TestScore1",
  //     "StartPosition": 0
  //   };
  //   URLpost("https://ABF47.playfabapi.com/Client/GetFriendLeaderboard", headers, body, callback);
  // }


  const getGlobalGameLeaderboard = (workoutID, callback) => {

    let headers = { 'Content-Type': 'application/json', 'X-SecretKey': 'UJ1HY3W6QPDA58EJRXDXQDSF5O648TDXKSJHTGYDXTUCPM9CF6' };

    let body = {
      "StatisticName": workoutID,
      "StartPosition": 0,
      "MaxResultsCount": 3
    };

    window.URLpost("https://ABF47.playfabapi.com/Server/GetLeaderboard", headers, body, callback);
  }


  return (
    <UserContext.Provider value={{ user, setURL, getGlobalGameLeaderboard }}>
      {props.children}
    </UserContext.Provider>
  );

}

export default UserProvider;


window.updatePlayerStatisticsOut = (workoutID, score) => {

  //console.log("Break 1");

  let nonStatisticIDs = ["w005", "w006"];

  let isValidStatisticID = (nonStatisticIDs.includes(workoutID)) ? false : true;

  window.getPlayerStatistics((response) => {

    //console.log("Break 2");
    //console.log("Response : ", response.data);

    let statisticLength = 0;
    if (response.data.Statistics.length == 0) {

      //console.log("Break 3");

      window.updatePlayerStatistics(window.Xauth, workoutID, score, (response) => {
        //console.log("Break 4");
        //console.log("Response : ", response);
        window.updateleaderBoardController = 0;
        window.updateLeaderBoard();
      });

    } else {

      response.data.Statistics.forEach((item) => {

        if (item.StatisticName === workoutID) {

          if (score > item.Value) {
            //console.log("Break 5");
            //console.log("Response : ", item.StatisticName);

            window.updatePlayerStatistics(window.Xauth, workoutID, score, (response) => {
              //console.log("Break 6");
              //console.log("Response : ", response);
              window.updateleaderBoardController = 0;
              window.updateLeaderBoard();
            });

          }

        } else {
          statisticLength++;
          //console.log("S3!");
          if (statisticLength == response.data.Statistics.length && isValidStatisticID) {
            //console.log("S5!");
            window.updatePlayerStatistics(window.Xauth, workoutID, score, (response) => {
              //console.log("S6!");
              window.updateleaderBoardController = 0;
              window.updateLeaderBoard();
            });
          }

        }

      });
    }

  });

}

window.getPlayerStatistics = (callback) => {
  let headers = { 'Content-Type': 'application/json', 'X-authentication': window.Xauth };
  let body = {};
  window.URLpost("https://ABF47.playfabapi.com/Client/GetPlayerStatistics", headers, body, callback);
}

window.updatePlayerStatistics = (Xauthentication, workoutID, value, callback) => { // reason for giving outside is to make it accessable in global and for all js

  let headers = { 'Content-Type': 'application/json', 'X-authentication': Xauthentication };

  let body = {
    "Statistics": [
      { "StatisticName": workoutID, "Value": value },
    ]
  };
  window.URLpost("https://ABF47.playfabapi.com/Client/UpdatePlayerStatistics", headers, body, callback);
}


window.URLpost = (url, reqHeader, reqBody, callback) => {
  const requestOptionsPOST = {
    method: "POST",
    headers: reqHeader,
    body: JSON.stringify(reqBody),
  }

  fetch(url, requestOptionsPOST)
    .then(response => response.json())
    .then(data => { 
      //console.log(data); 
      callback(data);
     })

}