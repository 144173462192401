import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";

//Providers
import UserProvider from "providers/UserProvider";

//Components
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import PrivateRoute from "./components/routers/privaterouter.component";
import Loader from "./components/loader/loader.component";

//Pages
import MainPage from "./pages/mainpage.jsx";
import WorkoutPage from "./pages/workoutpage.jsx";

//Analytics
import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-180768819-1");
    // To Report Page View
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname);
  }, []);

  return (
    <UserProvider>
      <div className="App" style={{ backgroundColor: "#f6f6f6" }}>
        <Header />
        <Loader isLoadingProp={true}></Loader>
        <Switch>
          <Route exact path="/" component={MainPage} />

          <PrivateRoute path="/workouts" component={WorkoutPage} exact />
          <PrivateRoute
            path="/workouts/:workoutID"
            component={WorkoutPage}
            exact
          />
          <PrivateRoute
            path="/workouts/:workoutID/:roomID"
            component={WorkoutPage}
          />
        </Switch>
        <Footer />
      </div>
    </UserProvider>
  );
}

export default App;
