import React, { useState, useContext, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { auth } from "../../firebase";

import { UserContext } from '../../providers/UserProvider';

import GameTimer from 'components/timer/timer.component.jsx'

const ScoreDataContainer = tw.div`flex justify-center flex-wrap lg:flex-row items-center lg:items-stretch relative `;

const UserInfo = tw.div`flex flex-wrap sm:flex-wrap items-center justify-center mt-8`;//lg:justify-start `;
const UserProfilePicture = tw.img`rounded-full w-16 h-16`;
const GameFeedback = tw.img`w-auto h-48 mt-1`;
const Title = tw.p`font-medium text-secondary-100`;
const ScoreContainer = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const ScoreData = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const UserTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const UserName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const UserTitle = tw.p`font-medium text-secondary-100`;

const Plan = styled.div`
  ${tw`w-full h-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8   relative pt-2 text-gray-900  flex flex-col`}

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
      background: rgb(85,60,154);
      background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
      background: rgb(76,81,191);
      background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl `}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-black text-4xl font-bold tracking-widest`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1`} // border-t-2 border-b-2 border-black
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;

const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;



const ScoreTimeContainer = tw.div`flex flex-col sm:flex-row justify-between mt-10`;
const ScoreTimeCol = tw.div`sm:w-1/2 flex flex-col`;
const ScoreDividerImage = tw.img`w-32 h-auto flex justify-center text-gray-500`;

const LeaderBoardContainer = tw.div`flex flex-col flex-row justify-between h-auto w-96 bg-white shadow-lg rounded-lg mt-4 text-left`;
const Steps = tw.ul`ml-4 mb-8`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div` md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-lg font-semibold text-gray-600`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;


const Illu = tw.img`w-auto h-64 mt-10`;

window.updateleaderBoardController = 0;

const ScoreDataComponent = ({
  UserData = null,
  steps = null,
  workoutID = "",
}) => {


  const { user, getGlobalGameLeaderboard } = useContext(UserContext);

  const [leaderBoard, setLeaderData] = useState([]);


  window.updateLeaderBoard = () => {

    getGlobalGameLeaderboard(workoutID, (lbResponse) => {
      console.log("USER : ",user);
      if (user) {
        var leaderboardDisplay = [];
        for (let i = 0; i < lbResponse.data.Leaderboard.length; i++) {
          leaderboardDisplay.push(
            {
              leaderName: lbResponse.data.Leaderboard[i].DisplayName,
              leaderStatValue: "Score : " + lbResponse.data.Leaderboard[i].StatValue
            }
          );
        }

        if (window.updateleaderBoardController == 0 ) {
          console.log("LBDATADISPLAY : ", leaderboardDisplay);
          setLeaderData(leaderboardDisplay);
          window.updateleaderBoardController = 1;
        }

      }
    });

  }

 

  const defaultdata =
  {
    name: user ? user["user"].displayName : "",
    profileImageSrc: user ? (user["user"].photoURL) ? user["user"].photoURL : "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/logo/avatarlogo.jpg" : "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/logo/avatarlogo.jpg",
    feedbackImageSrc: "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/images/great-wf.gif",
    Score: "1799",
    duration: "Hit",
  }

  const defaultSteps = [
    {
      heading: "-",
      description: "Score : -"
    },
    {
      heading: "-r",
      description: "Score : -"
    },
    {
      heading: "-",
      description: "Score : -"
    }
  ];

  let leads;
  if (!leads) leads = defaultSteps;


  if (!UserData) UserData = defaultdata;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];


  const ScoreTitle = tw.h1`text-3xl md:text-3xl leading-snug max-w-3xl text-black`;
  const ScoreTimeValue = tw.h1`text-primary-500 text-3xl md:text-6xl leading-snug max-w-3xl`;


  useEffect(() => {
  window.updateLeaderBoard();
  //console.log("LBDATA : ", leaderBoard);
  },[]);
  return (
    // <Container>
   
    <ScoreDataContainer>
      <UserInfo>
        <UserProfilePicture src={UserData.profileImageSrc} alt={UserData.name} />
        <UserTextInfo>
          <UserName>{UserData.name}</UserName>
        </UserTextInfo>
        {/* <GameFeedback id="gamefeedback" src="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/images/combos/transparentimg.png" /> */}
        <ScoreTimeContainer>
          <ScoreTimeCol>
            <ScoreTitle>Score</ScoreTitle>
            <ScoreTimeValue id="gamescore">{window.unity_gamescore}</ScoreTimeValue>
          </ScoreTimeCol>
          <ScoreDividerImage src={"https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/images/divider.png"}></ScoreDividerImage>
          <ScoreTimeCol>
            <GameTimer />
          </ScoreTimeCol>
        </ScoreTimeContainer>
        {/* <Illu src={"https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/images/front_illustration.png"} /> */}
        {
          (leaderBoard.length === 0) ?
            <LeaderBoardContainer>
              <Steps>
                <Step>
                  <StepText>
                    <StepHeading>{"Waiting..."}</StepHeading>
                    <StepDescription>{"Leaderboard"}</StepDescription>
                  </StepText>
                </Step>
              </Steps>
            </LeaderBoardContainer>
            :
            <LeaderBoardContainer>
              <Steps>
                {leaderBoard.map((lead, index) => (
                  <Step key={index}>
                    <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                    <StepText>
                      <StepHeading>{lead.leaderName}</StepHeading>
                      <StepDescription>{lead.leaderStatValue}</StepDescription>
                    </StepText>
                  </Step>
                ))}
              </Steps>
            </LeaderBoardContainer>
        }
      </UserInfo>
    </ScoreDataContainer>
    // </Container>
  );
};

export default ScoreDataComponent;