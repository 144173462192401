import React from "react";

const ScrollToTop = () => {
    const init = () =>{
        window.scrollTo(0, 0);
    }
    init();

    return(<></>);
}


// class ScrollToTop extends React.Component {
//     componentDidUpdate(prevProps) {
//       if (this.props.location !== prevProps.location || true) {
//         window.scrollTo(0, 0)
//       }
//     }
  
//     render() {
//       return this.props.children
//     }
//   }
  
  export default ScrollToTop;
