import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";

import ScoreDataComponent from "../components/scoredata/scoredata.component.jsx";
import WebcamCapture from "../components/webcam/webcam.component.jsx";
import UnityComponent from "../components/unity/unity.component.jsx";
import LinkGenerator from "../components/multiplayer/linkgenerator.component.jsx";
import ScrollToTop from "../components/scrolltotop/scrolltotop.component";

import ReactGA from "react-ga";

const Container = tw.div`text-white font-medium flex justify-center -mt-16 flex flex-row lg:flex-row max-w-screen-xl mx-auto py-20 md:py-24`;

const ParentColumn = tw.div`flex flex-wrap -mx-1 justify-center`;
var Column1 = tw.div`w-auto  my-3 px-3 flex items-center`;
var Column2 = tw.div`w-1/3  my-3 px-3  bg-gray-300 shadow-xl flex-wrap `;

const UnityContainer = tw.div`shadow-xl  overflow-hidden  flex justify-center`;
const WebCamContainer = tw.div`flex justify-center flex-wrap lg:flex-row items-center lg:items-stretch relative`; //shadow-xl rounded-2xl overflow-hidden flex justify-center h-full`;

const WorkoutPage = (props) => {
  const WORKOUT_ID = props.match.params.workoutID;

  const ROOM_ID = props.match.params.roomID;

  console.log("WORKOUT_ID : ", WORKOUT_ID);
  console.log("ROOM_ID : ", ROOM_ID);

  window.setLoading(true);

  useEffect(() => {
    if (ROOM_ID) {
      window.unityRoomID = ROOM_ID;
    } else {
      console.log("ROOM_ID : NOT-EXIST");
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname);
  });

  let workoutID;

  if (WORKOUT_ID) {
    workoutID = WORKOUT_ID;
  } else {
    workoutID = props.location.workoutID ? props.location.workoutID : "w001";
  }

  var workoutView;
  switch (workoutID) {
    case "w001":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
          <Column2>
            <ScoreDataComponent workoutID={workoutID} />
          </Column2>
        </>
      );

      window.current_workoutID = workoutID;
      break;

    case "w002":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"622.22px"}
                width={"700px"}
              />
            </UnityContainer>
          </Column1>
          <Column2 style={{ height: "622.22px" }}>
            <ScoreDataComponent workoutID={workoutID} />
          </Column2>
        </>
      );

      window.current_workoutID = workoutID;
      break;

    case "w003":
      Column1 = tw.div`w-auto  my-3 px-3`;

      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"622.22px"}
                width={"700px"}
              />
            </UnityContainer>
          </Column1>
          <Column2 style={{ height: "622.22px" }}>
            <ScoreDataComponent workoutID={workoutID} />
          </Column2>
        </>
      );

      window.current_workoutID = workoutID;
      break;

    case "w004":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"622.22px"}
                width={"700px"}
              />
            </UnityContainer>
          </Column1>
          <Column2 style={{ height: "622.22px" }}>
            <ScoreDataComponent workoutID={workoutID} />
          </Column2>
        </>
      );
      window.current_workoutID = workoutID;
      break;
    case "w005":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
        </>
      );
      window.current_workoutID = workoutID;
      break;

    case "w008":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
        </>
      );
      window.current_workoutID = workoutID;
      break;

    case "w006":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"576px"}
                width={"1024px"}
              />
            </UnityContainer>
          </Column1>
        </>
      );
      window.current_workoutID = workoutID;
      break;

    case "w009":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
        </>
      );
      window.current_workoutID = workoutID;
      break;

    case "w010":
      Column1 = tw.div`w-auto  my-3 px-3`;
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={workoutID}
                roomID={ROOM_ID}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
          <Column2>
            <ScoreDataComponent workoutID={workoutID} />
          </Column2>
        </>
      );

      window.current_workoutID = workoutID;
      break;
    
      case "w013":
        Column1 = tw.div`w-auto  my-3 px-3`;
        workoutView = (
          <>
            <Column1>
              <UnityContainer>
                <UnityComponent
                  workoutID={workoutID}
                  roomID={ROOM_ID}
                  height={"450px"}
                  width={"800px"}
                />
              </UnityContainer>
            </Column1>
            <Column2>
              <ScoreDataComponent workoutID={workoutID} />
            </Column2>
          </>
        );
  
        window.current_workoutID = workoutID;
        break;

    case "w007":
      Column1 = tw.div`w-2/3  my-3 px-3`; // shadow-xl flex justify-center`;
      workoutView = (
        <>
          <Column1>
            {" "}
            <video
              id="grannyblock"
              src="https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/assets/videos/granny.mp4"
              controls={false}
              autoPlay
              onPlaying={() => {
                window.loadVideoPosenet();
              }}
            >
              Text
            </video>{" "}
          </Column1>
          <Column2>
            {" "}
            <WebCamContainer>
              {" "}
              <WebcamCapture />{" "}
            </WebCamContainer>
          </Column2>
        </>
      );
      break;
    
      case "w015":
        Column1 = tw.div`w-auto  my-3 px-3`;
        workoutView = (
          <>
            <Column1>
              <UnityContainer>
                <UnityComponent
                  workoutID={workoutID}
                  roomID={ROOM_ID}
                  height={"450px"}
                  width={"800px"}
                />
              </UnityContainer>
            </Column1>
            <Column2>
              <ScoreDataComponent workoutID={workoutID} />
            </Column2>
          </>
        );
  
        window.current_workoutID = workoutID;
      break;
    
      case "w016":
        Column1 = tw.div`w-auto  my-3 px-3`;
        workoutView = (
          <>
            <Column1>
              <UnityContainer>
                <UnityComponent
                  workoutID={workoutID}
                  roomID={ROOM_ID}
                  height={"450px"}
                  width={"800px"}
                />
              </UnityContainer>
            </Column1>
            <Column2>
              <ScoreDataComponent workoutID={workoutID} />
            </Column2>
          </>
        );
  
        window.current_workoutID = workoutID;
      break;
    
      case "w011":
        Column1 = tw.div`w-auto  my-3 px-3`;
        workoutView = (
          <>
            <Column1>
              <UnityContainer>
                <UnityComponent
                  workoutID={workoutID}
                  roomID={ROOM_ID}
                  height={"450px"}
                  width={"800px"}
                />
              </UnityContainer>
            </Column1>
            <Column2>
              <ScoreDataComponent workoutID={workoutID} />
            </Column2>
          </>
        );
  
        window.current_workoutID = workoutID;
        break;

    default:
      console.error("Workout ID : ", workoutID, "not in the context!");
      workoutView = (
        <>
          <Column1>
            <UnityContainer>
              <UnityComponent
                workoutID={"w001"}
                height={"600px"}
                width={"800px"}
              />
            </UnityContainer>
          </Column1>
          <Column2>
            <ScoreDataComponent workoutID={"w001"} />
          </Column2>
        </>
      );
      break;
  }

  return (
    <>
      <ScrollToTop />
      <Container style={{ backgroundColor: "#f6f6f6" }}>
        <ParentColumn>{workoutView}</ParentColumn>
      </Container>
      <LinkGenerator props={props} />
    </>
  );
};

export default WorkoutPage;
