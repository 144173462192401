import React from "react";
import Webcam from "react-webcam";
import tw from "twin.macro";



const videoConstraints = {
    width: 480,
    height: 768,
    facingMode: "user"
};

const WebcamCapture = () => {
    const webcamRef = React.useRef(null);
    //const size = useDimensions(webcamRef);
      
    return (
        <Webcam
           audio={false}
          height={0}
          // ref={webcamRef}
           mirrored = {true}
           //width={0}
           videoConstraints={videoConstraints}
        />
    );
  };

  export default WebcamCapture;