import React, { useState } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import Lottie from "react-lottie";
import * as animationData from "../../lottie/pacmanloading.json";

const UnityComponent = ({ workoutID, roomID, height, width }) => {
  const [loader, setLoader] = useState(true);
  const [progress, setProgress] = useState(0);
  const unityContext = new UnityContext(Workouts[workoutID]);

  unityContext.on("loaded", () => {
    console.log("React : Unity Loaded");
    window.unityInstance_controller = unityContext;
    window.setLoading(false);
    setLoader(false);
  });

  unityContext.on("progress", (progression) => {
    console.log(progression * 100);
    setProgress(Math.round(progression * 100));
  });

  unityContext.on("quitted", () => {
    window.updateleaderBoardController = 0;
    window.initToggleControl = 0;
    window.userAuthcheckControl = 0;
    window.dropController();
    console.log("Game Quit : Success!");
  });

  let unityMode = roomID ? "multiplayer" : "singleplayer";

  let unityView;

  switch (unityMode) {
    case "singleplayer":
      console.log("Mode : Singleplayer");
      unityView = (
        <div style={{ position: "relative" }}>
          {loader ? (
            <div>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData.default,
                }}
                height={"100%"}
                width={"100%"}
                style={{
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <p
                style={{
                  marginTop: 50,
                  height: "100%",
                  width: "100%",
                  fontSize: 50,
                  color: "grey",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {"Loading " + progress + "%"}
              </p>
            </div>
          ) : (
            <></>
          )}
          <Unity unityContext={unityContext} height={height} width={width} />
        </div>
      );
      break;

    case "multiplayer":
      console.log("Mode : Multiplayer");
      unityView = (
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          <Unity unityContext={unityContext} height={height} width={width} />
        </div>
      );
      break;
  }

  return <>{unityView}</>;
};

export default UnityComponent;

let URLPrefix = "https://s3.ap-south-1.amazonaws.com/cdn.fxgetactive.in.dev/";

const Workouts = {
  w001: {
    loaderUrl: URLPrefix + "workouts/SaladSlicer/Build/SaladSlicer.loader.js",
    dataUrl: URLPrefix + "workouts/SaladSlicer/Build/SaladSlicer.data",
    frameworkUrl:
      URLPrefix + "workouts/SaladSlicer/Build/SaladSlicer.framework.js",
    codeUrl: URLPrefix + "workouts/SaladSlicer/Build/SaladSlicer.wasm",
  },
  w002: {
    loaderUrl: URLPrefix + "workouts/NinjaRun/Build/NinjaRun.loader.js",
    dataUrl: URLPrefix + "workouts/NinjaRun/Build/NinjaRun.data",
    frameworkUrl: URLPrefix + "workouts/NinjaRun/Build/NinjaRun.framework.js",
    codeUrl: URLPrefix + "workouts/NinjaRun/Build/NinjaRun.wasm",
  },
  w003: {
    loaderUrl: URLPrefix + "workouts/RopedIn/Build/RopedIn.loader.js",
    dataUrl: URLPrefix + "workouts/RopedIn/Build/RopedIn.data",
    frameworkUrl: URLPrefix + "workouts/RopedIn/Build/RopedIn.framework.js",
    codeUrl: URLPrefix + "workouts/RopedIn/Build/RopedIn.wasm",
  },
  w004: {
    loaderUrl:
      URLPrefix + "workouts/KingOfTheTree/Build/KingOfTheTree.loader.js",
    dataUrl: URLPrefix + "workouts/KingOfTheTree/Build/KingOfTheTree.data",
    frameworkUrl:
      URLPrefix + "workouts/KingOfTheTree/Build/KingOfTheTree.framework.js",
    codeUrl: URLPrefix + "workouts/KingOfTheTree/Build/KingOfTheTree.wasm",
  },
  w005: {
    loaderUrl:
      URLPrefix + "workouts/GetFitWithGranny/Build/GetFitWithGranny.loader.js",
    dataUrl:
      URLPrefix + "workouts/GetFitWithGranny/Build/GetFitWithGranny.data",
    frameworkUrl:
      URLPrefix +
      "workouts/GetFitWithGranny/Build/GetFitWithGranny.framework.js",
    codeUrl:
      URLPrefix + "workouts/GetFitWithGranny/Build/GetFitWithGranny.wasm",
  },
  w006: {
    loaderUrl:
      URLPrefix + "workouts/MeditaionApp/Build/MeditationApp.loader.js",
    dataUrl: URLPrefix + "workouts/MeditaionApp/Build/MeditationApp.data",
    frameworkUrl:
      URLPrefix + "workouts/MeditaionApp/Build/MeditationApp.framework.js",
    codeUrl: URLPrefix + "workouts/MeditaionApp/Build/MeditationApp.wasm",
    streamingAssetsUrl:
      URLPrefix + "workouts/MeditaionApp/Build/StreamingAssets",
  },
  w008: {
    loaderUrl:
      URLPrefix + "workouts/DanceWithGranny/Build/DanceWithGranny.loader.js",
    dataUrl: URLPrefix + "workouts/DanceWithGranny/Build/DanceWithGranny.data",
    frameworkUrl:
      URLPrefix + "workouts/DanceWithGranny/Build/DanceWithGranny.framework.js",
    codeUrl: URLPrefix + "workouts/DanceWithGranny/Build/DanceWithGranny.wasm",
  },
  w009: {
    loaderUrl: URLPrefix + "workouts/Yogasana/Build/Yogasana.loader.js",
    dataUrl: URLPrefix + "workouts/Yogasana/Build/Yogasana.data",
    frameworkUrl: URLPrefix + "workouts/Yogasana/Build/Yogasana.framework.js",
    codeUrl: URLPrefix + "workouts/Yogasana/Build/Yogasana.wasm",
  },
  w010: {
    loaderUrl: URLPrefix + "workouts/Galacton/Build/Galacton.loader.js",
    dataUrl: URLPrefix + "workouts/Galacton/Build/Galacton.data",
    frameworkUrl: URLPrefix + "workouts/Galacton/Build/Galacton.framework.js",
    codeUrl: URLPrefix + "workouts/Galacton/Build/Galacton.wasm",
  },
  w011: {
    loaderUrl: URLPrefix + "workouts/AerialRush/Build/AerialRush.loader.js",
    dataUrl: URLPrefix + "workouts/AerialRush/Build/AerialRush.data",
    frameworkUrl: URLPrefix + "workouts/AerialRush/Build/AerialRush.framework.js",
    codeUrl: URLPrefix + "workouts/AerialRush/Build/AerialRush.wasm",
  },
  w013: {
    loaderUrl: URLPrefix + "workouts/GoldenBubble/Build/GoldenBubble.loader.js",
    dataUrl: URLPrefix + "workouts/GoldenBubble/Build/GoldenBubble.data",
    frameworkUrl: URLPrefix + "workouts/GoldenBubble/Build/GoldenBubble.framework.js",
    codeUrl: URLPrefix + "workouts/GoldenBubble/Build/GoldenBubble.wasm",
  },
  w015: {
    loaderUrl: URLPrefix + "workouts/BusSubwayWeb/Build/BusSubwayWeb.loader.js",
    dataUrl: URLPrefix + "workouts/BusSubwayWeb/Build/BusSubwayWeb.data",
    frameworkUrl: URLPrefix + "workouts/BusSubwayWeb/Build/BusSubwayWeb.framework.js",
    codeUrl: URLPrefix + "workouts/BusSubwayWeb/Build/BusSubwayWeb.wasm",
  },
  w016: {
    loaderUrl: "https://s3.ap-south-1.amazonaws.com/com.development.fxgetactive/QuizapPAH/Build/Quizap.loader.js",
    dataUrl: "https://s3.ap-south-1.amazonaws.com/com.development.fxgetactive/QuizapPAH/Build/Quizap.data",
    frameworkUrl: "https://s3.ap-south-1.amazonaws.com/com.development.fxgetactive/QuizapPAH/Build/Quizap.framework.js",
    codeUrl: "https://s3.ap-south-1.amazonaws.com/com.development.fxgetactive/QuizapPAH/Build/Quizap.wasm",
  },
};

//GetFitWithGranny
