import React, { useState } from "react";
import tw from "twin.macro";

const TimerTitle = tw.h1`text-3xl md:text-3xl leading-snug max-w-3xl text-black`;
const TimerValue = tw.h1`text-primary-500 text-3xl md:text-6xl leading-snug max-w-3xl`;

let timer;
let currentSecond = 0;



const GameTimer = () => {

    const [seconds, setSecond] = useState('');

    const startTimer = () => {

        (timer) ? clearInterval(timer) : console.log("Timer : ", timer);

        currentSecond = 0;

        timer = setInterval(() => {
            currentSecond += 1;
            setSecond(currentSecond);
        }, 1000);

    }

    const stopTimer = () => {
        clearInterval(timer);
    }

    const initializeTimer = () => {
        window.startGameTimer = startTimer;
        window.stopGameTimer = stopTimer;
    }

    initializeTimer();

    return (
        <>
            <TimerTitle >Time</TimerTitle>
            <TimerValue >{(seconds)?seconds:0}</TimerValue>
        </>
    );
}

export default GameTimer;